// const url = "http://localhost:2155/api/v1"; // localhost
const url = "https://node-recruiting.mobiloitte.io/api/v1"; //staging
// const url = "https://7a9b-182-71-75-106.ngrok-free.app/api/v1"; // ngrock
// const url = "http://172.16.1.132:2155/api/v1"; //Rajnikant
// const url = "http://172.16.1.147:2155/api/v1"; //Ansh
// const url = "http://172.16.6.41:2155/api/v1"; //Anurag
// const url = "http://172.16.6.83:2155/api/v1"; //local izhar
// const url = "http://172.16.2.18:2155/api/v1"; //local harsh
// const url = "http://172.16.2.17:2155/api/v1"; //Rajnikant

const ApiConfig = {
  url,
  adminLogin: `${url}/admin/adminLogin`,
  sendOtp: `${url}/admin/resendOTP`,
  verifyOtp: `${url}/admin/verifyOTP`,
  resetPassword: `${url}/admin/resetPassword`,
  updateProfile: `${url}/admin/updateProfile`,
  getProfile: `${url}/admin/getProfile`,
  updatePassword: `${url}/admin/updatePassword`,
  getIndustries: `${url}/admin/getIndustries`,
  getAllNotifications: `${url}/notification/getAllNotifications`,
  updateNotificationStatus: `${url}/notification/updateNotificationStatus`,

  //Blog Management
  getBlogListing: `${url}/admin/listBlogs`,
  addUpdateBlog: `${url}/admin/addUpdateBlog`,
  blockUnblockBlog: `${url}/admin/blockUnblockBlog`,
  deleteBlog: `${url}/admin/deleteBlog`,
  getBlog: `${url}/admin/viewBlog`,
  getBlogCategoryListing: `${url}/admin/listBlogCategories`,

  //Static Content Management
  getAllStaticContentByType: `${url}/staticContent/getAllStaticContentByType`,
  updateStaticContent: `${url}/staticContent/updateStaticContent`,
  deleteStaticContent: `${url}/staticContent/deleteStaticContent`,
  addStaticContent: `${url}/staticContent/addStaticContent`,
  blockUnblockBlogCategory: `${url}/admin/blockUnblockBlogCategory`,
  deleteBlogCategory: `${url}/admin/deleteBlogCategory`,
  addUpdateBlogCategory: `${url}/admin/addUpdateBlogCategory`,
  getBlogCategory: `${url}/admin/viewBlogCategory`,
  getAllBlogCategory: `${url}/admin/getAllBlogCategories`,

  //Job location
  getJobLocationListing: `${url}/admin/listJobLocations`,
  deleteJobLocation: `${url}/admin/deleteJobLocation`,
  addUpdateJobLocation: `${url}/admin/addUpdateJobLocation`,
  getJobLocation: `${url}/admin/viewJobLocation`,

  //Upload file
  uploadFile: `${url}/common/uploadFile`,

  //Country
  getAllCountries: `${url}/user/getAllCountry`,

  //Subadmin
  createSubadmin: `${url}/admin/createSubadmin`,
  listSubadmins: `${url}/admin/listSubadmins`,
  updateSubadmin: `${url}/admin/updateSubadmin`,
  deleteSubadmin: `${url}/admin/deleteSubadmin`,

  //Employer management
  listEmployer: `${url}/admin/listEmployer`,
  updateEmployerStatus: `${url}/admin/updateEmployerStatus`,
  listJobsByRecruiter: `${url}/admin/listJobsByRecruiter`,
  listJobs: `${url}/admin/listJobs`,
  listOfferedCandidates: `${url}/admin/listOfferedCandidates`,
  listSubscription: `${url}/admin/listSubscription`,

  //New Employer Request
  getAllEmployerRequest: `${url}/admin/listEmployerRequests`,
  approveEmployerRequest: `${url}/admin/approveEmployerRequest`,
  rejectEmployerRequest: `${url}/admin/rejectEmployerRequest`,

  //Candidate management
  listCandidates: `${url}/admin/listCandidates`,
  blockUnblockUserAPI: `${url}/admin/blockUnblockUserAPI`,
  getUserAppliedJobs11: `${url}/admin/getUserAppliedJobs11`,
  listActivityLogs: `${url}/admin/listActivityLogs`,

  //Testimonial management
  addUpdateTestimonial: `${url}/admin/addUpdateTestimonial`,
  listTestimonials: `${url}/admin/listTestimonials`,
  publishToggle: `${url}/admin/publishToggle`,
  deleteTestimonial: `${url}/admin/deleteTestimonial`,

  //Brands Management
  listBrands: `${url}/admin/listBrands`,
  addUpdateBrand: `${url}/admin/addUpdateBrand`,
  deleteBrand: `${url}/admin/deleteBrand`,

  //Dashboard
  getDashboardStatistics: `${url}/admin/getDashboardStatistics`,

  //Industry management
  addUpdateIndustry: `${url}/admin/addUpdateIndustry`,
  blockUnblockIndustry: `${url}/admin/blockUnblockIndustry`,
  listIndustries: `${url}/admin/listIndustries`,
  addUpdateIndustrySector: `${url}/admin/addUpdateIndustrySector`,
  blockUnblockIndustrySector: `${url}/admin/blockUnblockIndustrySector`,
  listIndustrySectors: `${url}/admin/listIndustrySectors`,
  addUpdateJobCategory: `${url}/admin/addUpdateJobCategory`,
  blockUnblockJobCategory: `${url}/admin/blockUnblockJobCategory`,
  listJobCategories: `${url}/admin/listJobCategories`,
  
  //subscription management
  createSubscription: `${url}/subscription/createSubscription`,
  createSubscriptionAdmin: `${url}/admin/createSubscription`,
  listSubscriptions: `${url}/admin/listSubscriptions`,
  blockUnblockSubscription: `${url}/admin/blockUnblockSubscription`,

  //
  getAppliedJobs: `${url}/admin/getAppliedJobs`,

  //
  getJobCategory: `${url}/admin/getJobCategory`,

  blockUnblockJob: `${url}/admin/blockUnblockJob`,

  // Skill management
  addUpdateSkill: `${url}/admin/addUpdateSkill`,
  listSkills: `${url}/admin/listSkills`,
  deleteSkills: `${url}/admin/deleteSkills`,

  //Tickets
  listTickets: `${url}/admin/listTickets`,
  replyToTicket: `${url}/admin/replyToTicket`,

  //Transaction
  listTransactions: `${url}/admin/listTransactions`,
};
export default ApiConfig;
