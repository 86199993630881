import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  SvgIcon,
  Toolbar,
  AppBar,
  Hidden,
  Grid,
  Box,
} from "@mui/material";
import { Menu as MenuIcon } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import logo from "../../../assets/Logo.svg";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { AuthContext } from "../../../context/Auth";
import { getDataHandlerWithToken } from "../../../apiConfig/service";
import { useTranslation } from "react-i18next";

// import Logo from "src/component/Logo";

// Styles
const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  padding: "7px 30px",
  background: "linear-gradient(135deg, #1F9874, #3ACAB6)",
  minHeight: "50px",
  [theme.breakpoints.down("sm")]: {
    padding: "0px 20px 0px 5px",
  },
  "& .MuiAppBar-positionFixed": {
    position: "unset",
  },
}));

const MainHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  "& svg": {
    color: theme.palette.text.primary,
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  "& .leftBox": {
    width: "100%",
    maxWidth: "42px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "42px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "42px",
    },
    "& img": {
      width: "100%",
      maxWidth: "42px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "42px",
        paddingLeft: "0 !important",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
  },
}));

const TopBar = ({
  selectedTab,
  onTabChange,
  className,
  onMobileNavOpen,
  ...rest
}) => {
  // Placeholder for theme setting and auth
  const navigate = useNavigate();
   const { setNotificationCount , notificationCount } = useContext(AuthContext);
  const { t} = useTranslation();
  const location = useLocation();
  const fetchNotifications = async () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userId = user?.id;

    try {
      const response = await getDataHandlerWithToken("getAllNotifications", {
        adminId: userId,
      });

      if (response.responseCode === 200) {
        const allNotifications = response?.result?.notifications;
        const newNotifications = allNotifications.filter((notification) =>
          notification.admin.some(
            (admin) => admin.adminId === userId && admin.status === "NEW"
          )
        );
        setNotificationCount(newNotifications.length);
      } else {
        console.log(t("notificationsPage.somethingWentWrong"));
      }
    } catch (error) {
      console.error(t("notificationsPage.errorFetchingNotifications"), error);
    }
  };
    useEffect(() => {
      fetchNotifications();
    }, [location]);
  
  return (
    <AppBar
      elevation={0}
      className={className}
      color="inherit"
      sx={{ boxShadow: "0px 4px 4px rgb(0 0 0 / 10%)" }}
      {...rest}
    >
      <ToolbarStyled>
        {window.location.pathname === "/" ||
        window.location.pathname === "/forget" ||
        window.location.pathname === "/verify-otp" ||
        window.location.pathname === "/reset-password" ? null : (
          <Hidden mdUp>
            <IconButton
              color="white"
              onClick={onMobileNavOpen}
              sx={{ padding: "0px" }}
            >
              <SvgIcon>
                <MenuIcon style={{ color: "white", fontSize: "25px" }} />
              </SvgIcon>
            </IconButton>
          </Hidden>
        )}
        &nbsp; &nbsp;
        <MainHeader>
          <Grid container alignItems="center">
            <Grid item lg={3} md={3} sm={4} xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& img": {
                    cursor: "pointer",
                    width: "60%",
                    "@media (min-width:1440px)": {
                      width: "40%",
                    },
                  },
                }}
              >
                <img src={logo} alt="Logo" />

                <Link to="/dashboard" style={{ textDecoration: "none" }}></Link>
              </Box>
            </Grid>
            <Grid
              sx={{ display: "flex", justifyContent: "end" }}
              item
              lg={9}
              md={9}
              sm={8}
              xs={6}
            >
              <Badge
                badgeContent={notificationCount}
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "red", // Set the background color of the badge
                    color: "white", // Optionally set the text color inside the badge
                  },
                  cursor: "pointer",
                }}
                color="primary"
              >
                <NotificationsIcon
                  onClick={() => navigate("/notifications")}
                  color="action"
                  fontSize="large"
                />
              </Badge>
            </Grid>
            <Hidden xsDown>
              <Grid lg={4} md={6} sm={5} />
            </Hidden>
            <Grid lg={5} md={3} sm={3} xs={6}></Grid>
          </Grid>
        </MainHeader>
      </ToolbarStyled>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  selectedTab: PropTypes.string,
  onTabChange: PropTypes.func,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
